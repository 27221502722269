import APIService from '@/services/api-service'
const resource = '/receipt'

class ReceiptService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public save (header: any, lines: any) {
    return this.connector.post(`${resource}/save`, { header: header, lines: lines })
  }

  public getInvoiceNumbers () {
    return this.connector.get(`${resource}/invoice-numbers`)
  }

  public getReceipts () {
    return this.connector.get(`${resource}/all`)
  }

  public getReceipt (receiptId) {
    return this.connector.get(`${resource}/${receiptId}`)
  }

  public getInvoicesByClientId (clientId) {
    return this.connector.get(`${resource}/${clientId}/all-invoices`)
  }

  public getInvoicesByInvoiceId (invoiceId) {
    return this.connector.get(`${resource}/${invoiceId}/all`)
  }

  public generateReceiptNumber () {
    return this.connector.get(`${resource}/generate-receipt-no`)
  }
}

export default new ReceiptService()
