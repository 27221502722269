
import AddressService from '@/views/client/services/address-service'
import InvoiceService from '../services/invoice-service'
import RepositoryService from '../services/repository-service'
import ClientModal from '@/views/client/components/ClientModal.vue'
import CompanyModal from '@/views/company/components/CompanyModal.vue'
import TermsService from '../services/terms-service'
import ServiceModal from '@/views/operation/components/ServiceModal.vue'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import DateHelper from '@/mixins/date-mixins'
import Currencies from '@/data/currency.json'
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue'
import {
  MasterModule
} from '../store/MasterModule'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import {
  Printd
} from 'printd'
import {
  ToWords
} from 'to-words'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  required,
  helpers
} from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'
const alphatextarea = helpers.regex('alphatextarea', /^[\\/a-zA-Z0-9\s-/".,*"/gus]+$/)
  @Component({
    name: 'Invoice',
    components: {
      VueAutonumeric,
      ServiceModal,
      ClientModal,
      CompanyModal,
      draggable
    }
  })
export default class Invoice extends Vue {
    protected currencies = Currencies
    protected isEditForm = false
    protected drag = false
    protected showComponent = false
    protected showAmountToWordsField = false
    protected isAmountInWords = false
    protected document: any = null
    protected toWords: any = null
    protected clientAddress: any = null
    protected companyAddress: any = null
    protected companies: any = []
    protected clients: any = []
    protected services: any = []
    protected lines: any = []
    protected jobs: any = []
    protected contacts: any = []
    protected bank: any = {}
    protected banks: any = []
    protected job = {}
    protected paymentMethods: any = ['Bank', 'Cheque', 'Cash']
    protected paymentMethod = null
    protected paymentTerms: any = []
    protected paymentTerm: any = {
      id: null,
      description: null
    }

    protected converterOptions: any = {
      currency: false,
      doNotAddOnly: true,
      currencyOptions: {
        fractionalUnit: {
          name: 'Cents'
        }
      }
    }

    protected styles = ['https://api.smc-spl-application.com/print.css']

    protected header: any = {
      id: null,
      client: {},
      company: {},
      job: {},
      payment_term: {},
      contact_person: null,
      invoice_date: null,
      description: null,
      payment_method: null,
      invoice_currency: null,
      settlement_currency: null,
      invoice_amount: 0,
      exchange_rate: 0,
      gross_amount: 0,
      amount_in_words: null,
      company_address: null,
      client_address: null,
      bank_account: {},
      memorandum: null,
      remark: null,
      status: null,
      created_user: null,
      modified_user: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      header: {
        client: {
          required
        },
        company: {
          required
        },
        job: {
          required
        },
        contact_person: {
          required
        },
        company_address: {
          alphatextarea
        },
        invoice_currency: {
          required
        },
        payment_term: {
          required
        },
        payment_method: {
          required
        },
        memorandum: {
          alphatextarea
        },
        remark: {
          alphatextarea
        }
      }
    }

    get InvoiceAmount () {
      let txnAmount = 0
      return this.lines.reduce((total, line) => {
        txnAmount = (total + parseFloat((line.amount.toString()).replace(/,(?=\d{3})/g,
          '')))
        this.header.invoice_amount = txnAmount
        return txnAmount
      }, 0)
    }

    get GrossAmount () {
      let amount = 0
      if (this.header.exchange_rate === 0 || this.header.exchange_rate === '') {
        this.header.gross_amount = 0
        return 0
      } else {
        return this.lines.reduce((total, item) => {
          amount = (total + parseInt((item.amount.toString()).replace(/,(?=\d{3})/g, '')) * parseFloat(this.header
            .exchange_rate))
          this.header.gross_amount = amount
          return amount
        }, 0)
      }
    }

    get isDataChange () {
      return MasterModule.master
    }

    @Watch('isDataChange', {
      deep: true
    })
    repopulate () {
      if (this.isDataChange.isClientDataChanged) {
        this.populateClients()
        this.populateClientContacts(this.header.client.id)
        this.populateClientAddress(this.header.client.id)
        MasterModule.resetClientChange()
      }
      if (this.isDataChange.isCompanyDataChanged) {
        this.populateCompanies()
        this.populateCompanyAddress(this.header.company.id)
        this.populateBanks()
        MasterModule.resetCompanyChange()
      }
      if (this.isDataChange.isOperationDataChanged) {
        this.populateServices()
        MasterModule.resetOperationChange()
      }
    }

    public show (item: any, transaction: any) {
      this.showComponent = true
      this.populateClients()
      this.populateCompanies()
      this.populateServices()
      this.populateInvoiceTerms()

      if (Object.keys(transaction).length !== 0) {
        this.isEditForm = true
        this.populateInvoice(transaction.source_id)
      } else {
        this.isEditForm = false
        this.header.invoice_date = DateHelper.today('dd/mm/yyyy')
        this.addLine()
        this.setInvoice(item)
      }

      this.toWords = new ToWords(this.converterOptions)
      this.document = new Printd()
    }

    public hide () {
      this.cancel()
      this.showComponent = false
    }

    public openServiceModal () {
      (this.$refs.ServiceModal as any).show()
    }

    protected showClientModal () {
      if (Object.keys(this.header.client).length === 0) return false;
      (this.$refs.ClientInfoModal as any).populateClient(this.header.client.id);
      (this.$refs.ClientInfoModal as any).open()
    }

    protected showCompanyModal () {
      if (Object.keys(this.header.company).length === 0) return false;
      (this.$refs.CompanyInfoModal as any).populateCompany(this.header.company.id);
      (this.$refs.CompanyInfoModal as any).open()
    }

    protected async populateClients () {
      this.clients = []
      const response = await RepositoryService.getClients()
      this.clients = response.data
    }

    protected async populateCompanies () {
      this.companies = []
      const response = await RepositoryService.getCompanies()
      response.data.forEach((company) => {
        this.companies.push({
          value: {
            id: company.id,
            name: company.name
          },
          text: company.name
        })
      })
    }

    protected populateServices () {
      this.services = []
      RepositoryService.getServices().then((response) => {
        response.data.forEach((service) => {
          this.services.push({
            value: {
              id: service.id,
              name: service.name
            },
            text: service.name
          })
        })
      })
    }

    protected async populateInvoiceTerms () {
      const response = await TermsService.getInvoiceTerms()
      response.data.forEach((element) => {
        this.paymentTerms.push({
          value: {
            id: element.id,
            description: element.description
          },
          text: element.description
        })
      })
    }

    protected async populateJobs (clientId: number) {
      this.jobs = []
      const response = await RepositoryService.getJobs(clientId)
      this.jobs = response.data
    }

    protected populateJob (jobId: any) {
      InvoiceService.getJobDetails(jobId).then((response) => {
        const voyageNumber = response.data.voyage_number === null ? ' ' : response.data.voyage_number
        const description = response.data.vessel_name + ' - ' + voyageNumber + ' OF ' + response.data
          .vessel_arrival_date
        this.header.description = description
        this.header.contact_person = response.data.contact_person

        this.lines = response.data.inventories.map((line: any, index: number) => ({
          ...line,
          isEdit: false,
          line_number: index + 1,
          service: {
            id: line.service_id,
            name: line.service_type
          },
          description: line.description,
          unit_price: line.unit_price,
          quantity: line.quantity,
          amount: line.unit_price * line.quantity
        }))
      })
    }

    protected setInvoice (item: any = {}) {
      if (Object.keys(item).length === 0) return false
      this.header.client = {
        id: item.client_id,
        name: item.client_name
      }
      this.header.job = {
        id: item.id,
        number: item.job_number
      }
      this.populateClientContacts(item.client_id)
      this.populateClientAddress(item.client_id)
      this.populateJob(item.id)
    }

    protected populateInvoice (invoiceId: any) {
      InvoiceService.getInvoice(invoiceId).then((response) => {
        this.isAmountInWords = true
        this.header = response.data
        this.header.company = {
          id: response.data.company_id,
          name: response.data.company_name
        }
        this.header.client = {
          id: response.data.client_id,
          name: response.data.client_name
        }
        this.header.job = {
          id: response.data.job_id,
          number: response.data.invoice_number
        }
        this.header.payment_term = {
          id: response.data.payment_term_id,
          description: response.data.payment_term_description
        }
        this.header.bank_account = {
          id: response.data.bank_account_id,
          name: response.data.bank_account_name
        }
        this.populateBanks()
        this.populateBankAccount()
        this.populateClientContacts(this.header.client.id)

        this.lines = response.data.lines.map(line => ({
          ...line,
          isEdit: false,
          service: {
            id: line.service_id,
            name: line.service_type
          }
        }))
      })
    }

    protected editLine (lineIndex: number) {
      this.lines[lineIndex].isEdit = !this.lines[lineIndex].isEdit
    }

    protected addLine () {
      this.lines.push({
        id: null,
        isEdit: true,
        line_number: null,
        service: {},
        description: null,
        unit_price: null,
        quantity: null,
        amount: 0
      })
    }

    protected deleteLine (line, tableIndex) {
      const msg = `Are you sure you want to delete line ${tableIndex + 1}?`
      if (confirm(msg)) {
        if (line.id !== null) {
          InvoiceService.deleteInvoiceLine(this.header.id, line.id)
            .then(response => {
              ToastModule.message(response.data.message)
              this.lines.splice(tableIndex, 1)
            })
            .catch(error => ToastModule.message(error.response.data.message))
        } else this.lines.splice(tableIndex, 1)
      }
    }

    protected deleteLines () {
      const msg = 'Are you sure you want to delete all lines ?'
      if (confirm(msg) === true) {
        if (this.isEditForm) return true
        else {
          this.lines = []
          this.addLine()
        }
      }
    }

    protected setExchangeRate () {
      if (this.header.exchange_rate === '') this.header.exchange_rate = 0
    }

    protected convertAmountToWords () {
      if (this.header.gross_amount === 0 || this.header.gross_amount === null) {
        this.header.amount_in_words = null
        this.header.amount_in_words = this.toWords.convert(this.header.invoice_amount)
      } else {
        this.header.amount_in_words = null
        this.header.amount_in_words = this.toWords.convert(this.header.gross_amount)
      }
    }

    protected async populateClientData (client: any) {
      if (Object.keys(client).length === 0 || client === null) return false
      this.populateJobs(client.id)
      this.populateClientContacts(client.id)
      this.populateClientAddress(client.id)
    }

    protected async populateClientAddress (clientId = 0) {
      const response = await AddressService.getClientAddress(clientId)
      this.header.client_address = response.data.address
    }

    public async populateClientContacts (clientId: number) {
      this.contacts = []
      const response = await RepositoryService.getClientContacts(clientId)
      response.data.forEach((element) => {
        this.contacts.push({
          value: `${element.title}. ${element.first_name}`,
          text: `${element.title}. ${element.first_name}`
        })
      })
    }

    protected setContact () {
      if (this.header.contact_person === null) this.showClientModal()
    }

    protected async populateCompanyAddress (companyId: any) {
      const response = await AddressService.getCompanyAddress(companyId)
      this.header.company_address = response.data.address
    }

    protected addPaymentTerm () {
      this.paymentTerm.created_user = AuthModule.user.fullname
      this.paymentTerm.modified_user = AuthModule.user.fullname
      TermsService.createInvoiceTerm(this.paymentTerm).then((response) => {
        ToastModule.message(response.data.message)
        this.paymentTerms = []
        this.populateInvoiceTerms();
        (this.$refs.PaymentTermDropdown as any).hide(true)
        this.reset(this.paymentTerm)
      }).catch(error => {
        console.log(error)
      })
    }

    /* protected async populateBankAccount () {
      if (this.header.payment_method === 'Bank - USD' || this.header.payment_method === 'Bank - LKR') {
        let currency: any = null
        if (this.header.payment_method === 'Bank - USD') currency = 'USD'
        else if (this.header.payment_method === 'Bank - LKR') currency = 'LKR'

        this.bank = {}
        const response = await InvoiceService.getBankAccount(this.header.company.id, currency)
        this.bank = response.data
      }
    } */

    protected async populateBankAccount () {
      this.bank = {}
      const response = await InvoiceService.getBankAccount(this.header.bank_account.id)
      this.bank = response.data
    }

    protected async populateBanks () {
      this.banks = []
      if (this.header.payment_method === 'Bank') {
        const response = await InvoiceService.getBanks(this.header.company.id, this.header.invoice_currency)
        response.data.forEach((element) => {
          this.banks.push({
            value: {
              id: element.id,
              name: element.bank_name + ' - ' + element.account_number
            },
            text: element.bank_name + ' - ' + element.account_number
          })
        })
      }
    }

    protected async setServiceDescription (line: any) {
      if (line.service === null) {
        this.openServiceModal()
      }
      const response = await InvoiceService.getServiceDescription(line.service.id)
      line.description = response.data.description
      line.unit_price = response.data.unit_price
    }

    protected calculateLineTotal (tableIndex) {
      this.lines[tableIndex].amount = this.lines[tableIndex].unit_price * this.lines[tableIndex].quantity
    }

    protected preview () {
      this.showPreviewModal()
    }

    protected saveAndPrint () {
      this.save()
      this.$nextTick(() => {
        this.print()
      })
    }

    protected saveAndClose () {
      this.save()
      this.$nextTick(() => {
        this.cancel()
        this.showComponent = false
      })
    }

    protected saveAndNew () {
      this.save()
      this.$nextTick(() => {
        this.cancel()
      })
    }

    protected save () {
      if (!this.isValidated()) return false
      // this.convertAmountToWords()
      const header: any = {}
      const lines: any = []
      Object.keys(this.header).forEach(key => {
        header[key] = this.header[key]
        header.created_user = AuthModule.user.fullname
        header.modified_user = AuthModule.user.fullname
        header.client_id = this.header.client.id
        header.client_name = this.header.client.name
        header.company_id = this.header.company.id
        header.company_name = this.header.company.name
        header.job_id = this.header.job.id
        header.invoice_number = this.header.job.number
        header.payment_term_id = this.header.payment_term.id
        header.payment_term_description = this.header.payment_term.description
        header.bank_account_id = this.header.bank_account.id
        header.bank_account_name = this.header.bank_account.name
        header.gross_amount = this.header.gross_amount !== 0 ? this.header.gross_amount : this.header
          .invoice_amount
        delete header.company
        delete header.client
        delete header.job
        delete header.payment_term
        delete header.bank_account
      })
      this.lines.forEach((line, index) => {
        lines.push({
          invoice_id: this.header.id,
          line_number: index + 1,
          service_id: line.service.id,
          service_name: line.service.name,
          description: line.description,
          unit_price: line.unit_price,
          quantity: line.quantity,
          amount: line.amount,
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        })
      })

      if (this.isEditForm) this.update(header, lines)
      else this.create(header, lines)
    }

    protected create (header: any, lines: any) {
      InvoiceService.create(header, lines).then((response) => {
        this.header.id = response.data.id
        ToastModule.message(response.data.message);
        (this.$parent as any).populateJobs()
        this.isEditForm = true
        // return new Promise<void>((resolve) => { resolve() })
      }).catch(error => {
        NotifyModule.set('There was a problem saving your data, Please check and retry again')
        console.log(error)
      })
    }

    protected update (header: any, lines: any) {
      InvoiceService.update(header, lines).then((response) => {
        ToastModule.message(response.data.message);
        (this.$parent as any).populateJobs()
      }).catch(error => {
        NotifyModule.set('There was a problem saving your data, Please check and retry again')
        console.log(error)
      })
    }

    protected isValidated () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyModule.set('There are fields that require your attention')
        return false
      }

      const lineCount = this.lines.filter(line => Object.keys(line.service).length === 0).length
      if (lineCount > 0) {
        NotifyModule.set('It appears that the invoice lines are empty, Please check..')
        return false
      }

      return true
    }

    protected print () {
      this.document.print(document.getElementById('printInvoice'), this.styles)
    }

    public showPreviewModal () {
      (this.$refs.PreviewInvoiceModal as any).show()
    }

    public hidePreviewModal () {
      (this.$refs.PreviewInvoiceModal as any).hide()
    }

    public cancel () {
      this.reset(this.header)
      this.isAmountInWords = false
      this.isEditForm = false
      this.header.client = {}
      this.header.company = {}
      this.header.job = {}
      this.header.payment_term = {}
      this.header.bank_account = {}
      this.header.exchange_rate = 0
      this.header.invoice_amount = 0
      this.header.gross_amount = 0
      this.bank = {}
      this.lines = []
      this.services = []
      this.companies = []
      this.clients = []
      this.jobs = []
      this.contacts = []
      this.paymentTerms = []
      this.banks = []
      this.$nextTick(() => {
        this.$v.header.$reset()
      })
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

