import APIService from '@/services/api-service'
const resource = '/refund'

class RefundService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobs (clientId: number, type: string) {
    return this.connector.get(`${resource}/jobs/${clientId}/${type}`)
  }

  public getRefund (refundId: number) {
    return this.connector.get(`${resource}/${refundId}/refund-details`)
  }

  public createRefund (refund: any, lines:any) {
    return this.connector.post(`${resource}/create`, { refund: refund, lines: lines })
  }

  public updateRefund (refund: any, lines: any) {
    return this.connector.put(`${resource}/update`, { refund: refund, lines: lines })
  }

  public deleteLine (refundId: any, lineId: any) {
    return this.connector.delete(`${resource}/${refundId}/line/${lineId}`)
  }
}

export default new RefundService()
