import APIService from '@/services/api-service'
const resource = '/invoice/repository'

class RepositoryService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getClients () {
    return this.connector.get(`${resource}/clients`)
  }

  public getClientContacts (clientId: number) {
    return this.connector.get(`${resource}/client/${clientId}/contacts`)
  }

  public getCompanies () {
    return this.connector.get('/company/names')
  }

  public getServices () {
    return this.connector.get(`${resource}/services`)
  }

  public getJobs (clientId) {
    return this.connector.get(`${resource}/${clientId}/jobs`)
  }
}

export default new RepositoryService()
