import APIService from '@/services/api-service'
const resource = 'client/address'

class AddressService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }

  public getClientAddress (sourceId: any) {
    return this.connector.get(`${resource}/${sourceId}`)
  }

  public getCompanyAddress (sourceId: any) {
    return this.connector.get(`${resource}/company/${sourceId}`)
  }

  public getAddress (id: any) {
    return this.connector.get(`${resource}/populate/client/${id}`)
  }

  public deleteAddress (addressId) {
    return this.connector.delete(`${resource}/${addressId}`)
  }

  public getAddresses (clientId) {
    return this.connector.get(`${resource}/all/${clientId}`)
  }

  public setPrimaryAddress (clientId: any, addressId: any) {
    return this.connector.put(`${resource}/set-primary/${clientId}/${addressId}`)
  }
}

export default new AddressService()
