import APIService from '@/services/api-service'
const resource = '/invoice'

class TermsService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getInvoiceTerms () {
    return this.connector.get(`${resource}/terms`)
  }

  public createInvoiceTerm (data: any) {
    return this.connector.post(`${resource}/term/create`, data)
  }
}

export default new TermsService()
