import APIService from '@/services/api-service'
const resource = '/invoice'

class InoviceService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobDetails (jobId: any) {
    return this.connector.get(`${resource}/job-details/${jobId}`)
  }

  public getBanks (companyId: any, currency:any) {
    return this.connector.get(`${resource}/${companyId}/${currency}/banks`)
  }

  public getBankAccount (accountId: any) {
    return this.connector.get(`${resource}/account/${accountId}`)
  }

  public getServiceDescription (serviceId: number) {
    return this.connector.get(`${resource}/service-description/${serviceId}`)
  }

  public deleteInvoiceLine (invoiceId: any, lineId: any) {
    return this.connector.delete(`${resource}/${invoiceId}/line/${lineId}`)
  }

  public create (header: any, lines: any) {
    return this.connector.post(`${resource}/create`, { header: header, lines: lines })
  }

  public getInvoice (invoiceId: any) {
    return this.connector.get(`${resource}/details/${invoiceId}`)
  }

  public update (header: any, lines: any) {
    return this.connector.put(`${resource}/update`, { header: header, lines: lines })
  }
}

export default new InoviceService()
