import APIService from '@/services/api-service'
const resource = '/invoice'

class TransactionService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobs (type: any) {
    return this.connector.get(`${resource}/jobs/${type}`)
  }

  public addToRefundList (jobs: any) {
    return this.connector.post(`${resource}/add-to-refund-list`, jobs)
  }

  public delete (type: any, id: any) {
    return this.connector.delete(`${resource}/${type}/delete/${id}`)
  }
}

export default new TransactionService()
