
import RefundService from '../services/refund-service'
import RepositoryService from '../services/repository-service'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import DateHelper from '@/mixins/date-mixins'
import Currencies from '@/data/currency.json'
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue'
import {
  Printd
} from 'printd'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
  @Component({
    name: 'Refund',
    components: {
      VueAutonumeric
    }
  })
export default class Refund extends Vue {
    protected currencies = Currencies
    protected types = ['Invoice', 'Non-Invoice']
    protected attentionTypes = ['Agent', 'Staff', 'Other']
    protected paymentMethods: any = ['Bank', 'Cheque', 'Cash']
    protected paymentDetails: any = ['Received', 'Received USD Draft']
    protected clients: any = []
    protected contacts: any = []
    protected attentionType: any = null
    protected document: any = null
    protected showComponent = false
    protected isEditForm: any = false
    protected jobs: any = []
    protected lines: any = []
    protected styles = ['https://api.smc-spl-application.com/print.css']

    protected refund: any = {
      id: null,
      date: null,
      refund_number: null,
      type: null,
      payment_method: null,
      payment_detail: null,
      client: {},
      job: {},
      invoiced_amount: null,
      amount: null,
      created_user: null,
      modified_user: null
    }

    protected fields = [{
      key: 'line_number',
      label: '#',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'type',
      label: 'Type',
      thStyle: {
        width: '10%'
      },
      class: 'text-left'
    },
    {
      key: 'name',
      label: 'Attention',
      thStyle: {
        width: '15%'
      },
      class: 'text-left'
    },
    {
      key: 'description',
      label: 'Description',
      thStyle: {
        width: '20%'
      },
      class: 'text-left'
    },
    {
      key: 'quantity',
      label: 'Quantity',
      thStyle: {
        width: '8%'
      },
      class: 'text-left'
    },
    {
      key: 'rate',
      label: 'Rate',
      thStyle: {
        width: '10%'
      },
      class: 'text-left'
    },
    {
      key: 'currency',
      label: 'Currency',
      thStyle: {
        width: '10%'
      },
      class: 'text-left'
    },
    {
      key: 'amount',
      label: 'Amount',
      thStyle: {
        width: '10%'
      },
      class: 'text-left'
    },
    {
      key: 'edit',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'delete',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    }
    ]

    $v: Vuelidate

    @Validations()
    validations = {
      refund: {
        date: {
          required
        },
        type: {
          required
        },
        payment_method: {
          required
        },
        client: {
          required
        },
        job: {
          required
        }
      }
    }

    @Watch('refund', {
      deep: true
    })
    setJobs () {
      if (this.refund.type !== null && Object.keys(this.refund.client).length !== 0) {
        this.populateJobs(this.refund.client.id)
      }
    }

    get transactionAmount () {
      let txnAmount = 0
      return this.lines.reduce((total, line) => {
        txnAmount = (total + parseFloat((line.amount.toString()).replace(/,(?=\d{3})/g,
          '')))
        this.refund.transaction_amount = txnAmount
        return txnAmount
      }, 0)
    }

    //  get attentions () {
    //    let attentions: any = []
    //    if (this.attentionType === 'Agent') {
    //      /* this.clients.forEach((element) => {
    //        attentions.push({
    //          value: element.name,
    //          text: element.name
    //        })
    //      }) */
    //      attentions = this.clients
    //    }
    //    return attentions
    //  }

    public show (item: any, transaction: any) {
      this.showComponent = true

      if (Object.keys(transaction).length !== 0) {
        this.isEditForm = true
        this.populateRefund(transaction.source_id)
      } else {
        this.isEditForm = false
        this.refund.date = DateHelper.today('dd/mm/yyyy')
        this.refund.client = {
          id: item.client_id,
          name: item.client_name
        }
        this.refund.job = {
          id: item.id,
          number: item.job_number
        }
        this.addLine()
      }
      this.document = new Printd()
      this.populateClients()
    }

    public hide () {
      this.cancel()
      this.showComponent = false
    }

    protected addLine () {
      this.lines = this.lines.map(line => ({
        ...line,
        isEdit: false
      }))

      this.lines.push({
        isEdit: true,
        id: null,
        type: null,
        name: null,
        description: null,
        quantity: null,
        rate: null,
        currency: null,
        exchange_rate: null,
        amount: 0,
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      })
    }

    protected editLine (lineIndex: number) {
      this.lines[lineIndex].isEdit = !this.lines[lineIndex].isEdit
    }

    protected deleteLine (data: any) {
      const msg = `Are you sure you want to delete line ${data.index + 1}?`
      if (confirm(msg) === true) {
        if (data.item.id !== null) {
          RefundService.deleteLine(this.refund.id, data.item.id).then((response) => {
            ToastModule.message(response.data.message)
            this.lines.splice(data.index, 1)
          }).catch(error => {
            ToastModule.message(error.response.data.message)
          })
        } else this.lines.splice(data.index, 1)
      }
    }

    protected deleteLines () {
      const msg = 'Are you sure you want to delete all lines ?'
      if (confirm(msg) === true) {
        this.lines = []
        this.addLine()
      }
    }

    protected populateRefund (refundId) {
      RefundService.getRefund(refundId).then((response) => {
        this.refund = response.data
        this.refund.client = {
          id: response.data.client_id,
          name: response.data.client_name
        }
        this.refund.job = {
          id: response.data.job_id,
          number: response.data.refund_number
        }
        this.lines = response.data.lines.map(line => ({
          ...line,
          isEdit: false,
          name: line.type === 'Agent' ? {
            id: null,
            name: line.name
          } : line.name,
          amount: line.amount
        }))

        this.populateJobs(response.data.client_id)
      })
    }

    protected async populateClients () {
      const response = await RepositoryService.getClients()
      this.clients = response.data
    }

    protected async populateJobs (clientId) {
      if (this.refund.type === null) {
        NotifyModule.set('Please select refund type to proceed')
        return false
      }
      const response = await RefundService.getJobs(clientId, this.refund.type)
      this.jobs = response.data

      this.populateClientContacts(clientId)
    }

    public async populateClientContacts (clientId: number) {
      const response = await RepositoryService.getClientContacts(clientId)
      this.contacts = response.data
    }

    protected setAttention (type: string) {
      this.attentionType = type
    }

    protected calculateLineTotal (tableIndex: number) {
      if (this.lines[tableIndex].currency === 'LKR') {
        this.lines[tableIndex].amount = +this.lines[tableIndex].quantity * +this.lines[tableIndex].rate * +this.lines[
          tableIndex].exchange_rate
      } else {
        this.lines[tableIndex].exchange_rate = null
        this.lines[tableIndex].amount = this.lines[tableIndex].quantity * this.lines[tableIndex].rate
      }
    }

    protected save () {
      if (!this.isValidated()) return false
      const refund: any = {}
      const lines: any = []
      Object.keys(this.refund).forEach(key => {
        refund[key] = this.refund[key]
        refund.job_id = this.refund.job.id
        refund.refund_number = this.refund.job.number
        refund.client_id = this.refund.client.id
        refund.client_name = this.refund.client.name
        refund.created_user = AuthModule.user.fullname
        refund.modified_user = AuthModule.user.fullname
        delete refund.client
        delete refund.job
      })
      this.lines.forEach((line) => {
        lines.push({
          id: line.id,
          type: line.type,
          name: line.type === 'Agent' ? line.name.name : line.name,
          description: line.description,
          quantity: line.quantity,
          rate: line.rate,
          currency: line.currency,
          exchange_rate: line.exchange_rate,
          amount: parseFloat(line.amount),
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        })
      })

      if (this.isEditForm) this.update(refund, lines)
      else this.create(refund, lines)
    }

    protected create (refund: any, lines: any) {
      RefundService.createRefund(refund, lines).then((response) => {
        ToastModule.message(response.data.message);
        (this.$parent as any).populateJobs()
        this.isEditForm = true
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected update (refund: any, lines: any) {
      RefundService.updateRefund(refund, lines).then((response) => {
        ToastModule.message(response.data.message);
        (this.$parent as any).populateJobs()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected isValidated () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyModule.set('There are fields that require your attention')
        return false
      }

      const lineCount = this.lines.filter(line => line.name === null).length
      if (lineCount > 0) {
        NotifyModule.set('It appears that the attention rates are empty, Please check..')
        return false
      }

      return true
    }

    protected print () {
      this.document.print(document.getElementById('printRefund'), this.styles)
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }

    protected cancel () {
      this.clients = []
      this.contacts = []
      this.jobs = []
      this.lines = []
      this.reset(this.refund)
      this.refund.job = {}
      this.refund.client = {}
      this.attentionType = null
      this.isEditForm = false
      this.$nextTick(() => {
        this.$v.refund.$reset()
      })
    }
}

