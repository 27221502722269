
import ClientService from '../../client/services/client-service'
import ReceiptService from '../services/receipt-service'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import DateHelper from '@/mixins/date-mixins'
import Printd from 'printd'
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue'
import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import { ReceiptModule } from '../store/ReceiptModule'
  @Component({
    name: 'JobIncome',
    components: {
      VueAutonumeric
    }
  })
export default class JobIncome extends Vue {
    protected showComponent = false
    protected clients: any = []
    protected paymentMethods: any = ['Bank', 'Cheque', 'Cash']
    protected invoiceNumbers: any = []
    protected styles = ['https://api.smc-spl-application.com/print.css']
    protected document: any = null
    protected jobId: any = null
    protected invoices: any = [{
      item_number: null,
      description: null,
      invoice_amount: 0,
      amount: 0
    }]

    protected header: any = {
      id: null,
      client: {},
      invoiceNumber: {
        id: null,
        number: null,
        clientId: null,
        clientName: null
      },
      receipt_number: null,
      receipt_date: null,
      amount_received: null,
      reference_number: null,
      transaction_method: null,
      transaction_currency: null,
      remarks: null,
      created_user: null,
      modified_user: null
    }

    protected fields = [{
      key: 'item_number',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'invoice_number',
      label: 'Description',
      thStyle: {
        width: '25%'
      },
      class: 'text-left'
    },
    {
      key: 'invoice_amount',
      label: 'Invoice Amount',
      thStyle: {
        width: '6%'
      },
      class: 'text-left'
    },
    {
      key: 'amount',
      label: 'Amount',
      thStyle: {
        width: '6%'
      },
      class: 'text-center'
    }
    ]

    get receivedAmount () {
      let amount = 0
      return this.invoices.reduce((total, line) => {
        amount = (total + parseFloat(line.amount))// (total + parseInt((line.amount.toString()).replace(/,(?=\d{3})/g,'')))
        this.header.amount_received = amount
        return amount
      }, 0)
    }

    public show (item: any, transaction: any) {
      this.showComponent = true
      if (transaction.source_type === 'receive') {
        this.populateReceipt(transaction.source_id)
      } else this.header.receipt_date = DateHelper.today('dd/mm/yyyy')

      this.jobId = transaction.reference_id
      this.document = new Printd()
      this.populateClientNames()
      this.populateInvoiceNumbers()
      this.setReceiptNumber()
    }

    public hide () {
      this.showComponent = false
      this.cancel();
      (this.$parent as any).component = null
    }

    protected populateReceipt (receiptId) {
      ReceiptService.getReceipt(receiptId).then((response) => {
        this.header = response.data
        this.header.client = {
          id: response.data.client_id,
          name: response.data.client_name
        }

        this.invoices = response.data.lines.map(line => ({
          item_number: line.item_number,
          invoice_number: line.description,
          invoice_amount: line.invoice_amount,
          amount: line.amount
        }))
      })
    }

    private async populateClientNames () {
      const response = await ClientService.getClientNames()
      response.data.forEach((element: {
        id: any;name: any
      }) => {
        this.clients.push({
          value: {
            id: element.id,
            name: element.name
          },
          text: element.name
        })
      })
    }

    private async populateInvoiceNumbers () {
      const response = await ReceiptService.getInvoiceNumbers()
      response.data.forEach((element: {
        id: any;invoiceNumber: any; clientId: any; clientName:any
      }) => {
        this.invoiceNumbers.push({
          value: {
            id: element.id,
            number: element.invoiceNumber,
            clientId: element.clientId,
            clientName: element.clientName
          },
          text: element.invoiceNumber
        })
      })
    }

    private populateInvoicesByClientId () {
      this.header.invoiceNumber = {}
      ReceiptService.getInvoicesByClientId(this.header.client.id).then((response) => {
        this.invoices = response.data.map((invoice: any, index: any) => ({
          ...invoice,
          item_number: index + 1,
          invoice_amount: invoice.balance === null ? invoice.gross_amount : invoice.balance,
          amount: 0
        }))
      })
    }

    private populateInvoicesByInvoiceId () {
      this.header.client = {
        id: this.header.invoiceNumber.clientId,
        name: this.header.invoiceNumber.clientName
      }
      ReceiptService.getInvoicesByInvoiceId(this.header.invoiceNumber.id).then((response) => {
        this.invoices = response.data.map((invoice: any, index: any) => ({
          ...invoice,
          item_number: index + 1,
          invoice_amount: invoice.balance === null ? invoice.gross_amount : invoice.balance,
          amount: null
        }))
      })
    }

    public setAmount () {
      this.invoices.forEach((invoice: any) => {
        invoice.amount = parseInt(this.header.amount_received) >= parseInt(invoice.balance) ? invoice.balance : 0
        console.log(invoice)
      })
    }

    public async setReceiptNumber () {
      // const date = DateHelper.yearMonth()
      const runningNumber = await ReceiptService.generateReceiptNumber()
      this.header.receipt_number = `${runningNumber.data}`
    }

    protected print () {
      this.document.print(document.getElementById('printReceipt'), this.styles)
    }

    protected save () {
      const header: any = {}
      const lines: any = []
      Object.keys(this.header).forEach(key => {
        header[key] = this.header[key]
        header.created_user = AuthModule.user.fullname
        header.modified_user = AuthModule.user.fullname
        header.client_id = this.header.client.id
        header.client_name = this.header.client.name
        header.transaction_currency = 'Rs'
        header.job_id = this.jobId
        delete header.client
      })
      this.invoices.forEach((invoice) => {
        lines.push({
          description: invoice.invoice_number,
          item_number: invoice.item_number,
          // due_date: invoice.due_date,
          invoice_amount: invoice.invoice_amount,
          amount: invoice.amount,
          invoice_id: invoice.id,
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        })
      })

      ReceiptService.save(header, lines).then((response) => {
        ToastModule.message(response.data.message);
        // this.print()
        (this.$parent as any).populateJobs()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected cancel () {
      this.invoices = []
      this.reset(this.header)
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

