
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import TransactionService from './services/transaction-serivce'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import RepositoryService from './services/repository-service'
import Invoice from './components/Invoice.vue'
import Refund from './components/Refund.vue'
import Receipt from './components/Receipt.vue'
import Status from '@/components/Status/index.vue'
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue'
import {
  AuthModule
} from '@/store/modules/AuthModule'
  @Component({
    name: 'Invoicing',
    components: {
      Invoice,
      Refund,
      Receipt,
      Status,
      VueAutonumeric
    }
  })
export default class Invoicing extends Vue {
    protected isTableBusy = false
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected searchTable = null
    protected activeIndex = -1
    protected clients: any = []
    protected action: any = null
    protected jobs: any = []
    protected types: any = ['Pending', 'Refund-list', 'Transactions']
    protected type: any = null
    protected client: any = null
    protected date: any = null

    protected fields = [{
      key: 'is_checked',
      label: '',
      thStyle: {
        width: '1%'
      },
      class: 'text-center custom-table-field'
    },
    {
      key: 'job_number',
      label: 'Job No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vessel_name',
      label: 'Vessel',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'vessel_arrival_date',
      label: 'Arrival Date',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'client_name',
      label: 'Client',
      thStyle: {
        width: '20%'
      }
    },
    {
      key: 'transactions',
      label: 'Transactions',
      thStyle: {
        width: '25%'
      }
    }
    ]

    created () {
      this.boot()
    }

    get user () {
      return AuthModule.user
    }

    get rows () {
      return this.jobs.length
    }

    get isLineChecked () {
      const count = this.jobs.filter(job => job.is_checked).length
      if (count !== 0) return true
      return false
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.type = 'Pending'
      this.populateJobs()
      this.populateClients()
    }

    protected rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.is_checked) return 'table-gray'
    }

    protected showTransactionComponent (type: string, item: any = {}, transaction: any = {}) {
      switch (type) {
        case 'invoice':
          (this.$refs.InvoiceComponent as any).show(item, transaction)
          break

        case 'refund':
          (this.$refs.RefundComponent as any).show(item, transaction)
          break

        case 'receipt':
          (this.$refs.ReceiptComponent as any).show(item, transaction)
          break
      }
    }

    protected populateClients () {
      RepositoryService.getClients().then((response) => {
        this.clients = response.data
      })
    }

    protected populateJobs () {
      this.jobs = []
      this.isTableBusy = true
      TransactionService.getJobs(this.type === 'Refund list' ? 'Refund-list' : this.type).then((response) => {
        this.isTableBusy = false
        this.jobs = response.data.map(job => ({
          ...job,
          is_checked: false
        }))
      })
    }

    protected addToRefundList () {
      const jobs: any = []
      this.jobs.forEach((job) => {
        if (job.is_checked === true) {
          jobs.push({
            id: job.id
          })
        }
      })
      TransactionService.addToRefundList(jobs).then((response) => {
        ToastModule.message(response.data.message)
        this.populateJobs()
      }).catch(error => {
        console.log(error)
      })
    }

    protected deleteTransactions () {
      const jobs: any = []
      this.jobs.forEach((job) => {
        if (job.is_checked === true) {
          jobs.push({
            id: job.id,
            transactions: job.transactions
          })
        }
      })
      console.log(jobs)
      TransactionService.deleteTransactions(jobs).then((response) => {
        ToastModule.message(response.data.message)
        this.populateJobs()
      }).catch(error => {
        console.log(error)
      })
    }

    protected deleteTransaction (type: any, id: any, transactions: any, columnIndex) {
      const msg = `Do you want to delete this ${type}?`
      if (confirm(msg) === true) {
        TransactionService.delete(type, id).then((response) => {
          ToastModule.message(response.data.message)
          transactions.splice(columnIndex, 1)
        }).catch(error => {
          console.log(error)
        })
      }
    }
}

